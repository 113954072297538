@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
button {
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s;
}
body {
  font-family: "Montserrat", sans-serif;
  transition: all 0.5s ease-in-out;
}

.slick-dots {
  background-color: black !important;
  position: absolute;
  bottom: -40px !important;
}
.slick-dots li.slick-active button:before {
  color: white !important;
}
.slick-dots li button:before {
  color: white !important;
  font-size: 10px !important;
}

a,
span {
  -webkit-tap-highlight-color: transparent !important;
  outline: none;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.opacityCloseAnimation {
  animation: fade-out 0.5s ease-in-out;
}

.opacityAnimation {
  animation: fade-in 0.8s ease-in-out;
}

@keyframes strikeSlide-in {
  0% {
    opacity: 0;
    transform: translatex(-10%);
  }
  100% {
    transform: translatex(0);
  }
}

.slideAnimation {
  animation: strikeSlide-in 0.3s ease-in-out;
  animation-delay: 500ms;
  animation-fill-mode: backwards;
}
/* SCROLLBAR COLOR CHANGE */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #1c1c1c;
}

::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 6px;
  border: 3px solid #1c1c1c;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #333;
}
html {
  scrollbar-base-color: #000;
  scrollbar-face-color: #000;
  scrollbar-highlight-color: #000;
  scrollbar-3dlight-color: #000;
  scrollbar-shadow-color: #000;
  scrollbar-darkshadow-color: #000;
  scrollbar-arrow-color: #fff;
  scrollbar-track-color: #1c1c1c;
}
